.page-alignment{
    display: flex;
    justify-content: center;
    align-items: center;
    place-items: center;
}

.about-us{
    margin-left: 4%;
    margin-right: 6%;
    margin-top: 1.5%;
    margin-bottom: 6%;
    width: 800px;
    height: auto;
    background-color: #7f7cf4;
    border-radius: 10px;
    box-shadow: 10px 10px 5px 10px #facc6b;
    font-size: 26px;
    padding: 5px;
    padding-left: 15px;
    text-align: left;
    color: #ffffff;    
}

.contact-us{
    margin-left: 4%;
    margin-top: 1.5%;
    margin-right: 6%;
    margin-bottom: 5%;
    width: 500px;
    height: auto;
    background-color: #7f7cf4;
    border-radius: 10px;
    box-shadow: 10px 10px 5px 10px #facc6b;
    border: 10px thick;
    padding: 5px;
    padding-left: 15px;
    text-align: left;
    color: #ffffff;  
}

a{
    color: white;
}
textarea{
    width: 450px;
    max-width: 480px;
    height: 250px;
}

@media screen and (max-width: 767px) {
    /* Styles for screens smaller than 768px (typical phone in portrait) */
    .page-alignment{
        display: flexbox;
        width: 100%;
    }
    .about-us{
        display: flexbox;
        width: 95%;
    }
    .contact-us{
        display: flexbox;
        width: 95%;  
    }
    textarea{
        width: 350px;
        max-width: 450px;
        height: 250px;
    }
  }

