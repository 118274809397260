.landing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .logo {
    opacity: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    animation: fadeIn 3s ease-in-out forwards;
    animation-delay: .2s;
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    /* Styles for screens smaller than 768px (typical phone in portrait) */
    body{
        overflow-x: hidden;
    }
    .landing-page{
        display: flexbox;
    }
    .logo {
        
        width: 100%; /* Example: reduce logo size */
    }
    /* Add other styles for smaller screens here */
  }
  

  @keyframes fadeIn {
    from{
        opacity: 0;
    }to{
        opacity: 1;
    }
    
  }
  
  .landing-page {
    opacity: 1;
  }
  