
    .message {
        opacity: 0; /* Initial state is invisible */
        transition: opacity 0.3s ease-in-out;
        background-color: #c4bcf3; /* Slightly transparent white, adjust as needed */
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); /* Center the element */
        width: 50%;
        height: 50%;
        font-size: 1.5rem;
        z-index: 1000; /* High value to ensure it's on top */
        display: flex;
        flex-direction: column;
        padding-left: 15px;
        padding-right: 5px;
        justify-content: center;
        align-items: center; /* Center the content inside the message box */
        border-radius: 10px; /* Optional: Add rounded corners */
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Optional: Add a shadow */
        color: white;
    }
    
    .message.show {
        opacity: 1; /* Make it visible */
    }

    .message button {
        /* Basic Styling */
        border: none;
        outline: none;
        cursor: pointer;
        background-color: #c4bcf3; /* Blue color, adjust as needed */
        color: #ffffff; /* White text color */
    
        /* Enhanced Visuals */
        border-radius: 5px; /* Rounded corners */
        box-shadow: 0 2px 5px #facc6b; /* Slight shadow for depth */
        transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    
        /* Typography and Spacing */
        font-size: 16px;
        font-weight: 600;
        padding: 10px 20px; /* Vertical and horizontal padding */
    }
    
    /* Hover effect */
    .message button:hover {
        background-color: #7f7cf4; /* Darker blue on hover, adjust as needed */
    }
    
    /* Active (click) effect */
    .message button:active {
        background-color: #7f7cf4; /* Even darker blue on active, adjust as needed */
        box-shadow: 0 1px 3px #facc6b; /* Reduce shadow for "pressed" look */
    }
    
    .message-contetn{
        padding: 20px;
    }
    .message-actions{
        display: block;
        margin-top: 10px;
        padding: 20px;
        text-align: center;
    }
    @media screen and (max-width: 767px) {
        /* Styles for screens smaller than 768px (typical phone in portrait) */
        .message{
            display: flexbox;
            width: 85%;
        }
    }
    